<div class="imageStage">
    <div class="content" fxLayout="row" fxLayout.lt-sm="column" ngClass.lt-sm="small">
      <div class="image" fxFlex="200px">
        <img src="{{ productInfo.imageUrl || 'assets/img/noimage.png' }}">
      </div>
      <div class="text">
        <h1 *ngIf="productInfo.productName" ngClass.lt-sm="small">
          {{ productInfo.productName }}, {{ productInfo.productType }}</h1>
        <h1 *ngIf="!productInfo.productName" ngClass.lt-sm="small">
          {{ productInfo.description }}</h1>
        <p>
          Serial Number: {{ productInfo.number }}<br>
          <strong>PRODUCT NOT SOLD</strong>
        </p>
      </div>
    </div>
  </div>
  
  <div class="content">
    <div class="tiles md-padding" fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
  
      <div *ngFor="let staticLinkBox of staticLinkBoxes" class="tile" fxFlex="100" fxFlex.gt-md="25" fxFlex.md="33"
           fxFlex.sm="50">
        <a class="headline" href="{{staticLinkBox.link}}" target="_blank">
          <div [style.backgroundImage]="'url('+ staticLinkBox.image +')'" class="tile-content">
            <div class="teaser-box">
              <span class="headline">{{staticLinkBox.text}}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  
    <!--pre>{{ productInfo | json }}</pre-->
  </div>
  
  