import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export type Status = 'major_outage' | 'under_maintenance' | 'partial_outage' | 'degraded_performance' | 'operational';

// const STATUSPAGE = 'https://api.viessmann.com/status/v1/apps/n9d3h7qlzy0p/summary';
const STATUSPAGE = environment.statusPage;

export interface PlannedMaintenance {
  start_date: string;
  end_date: string;
  remind_from: string;
  status: string;
}

export interface StatusSummary {
  id: string;
  name: string;
  status: Status;
  next_maintenance?: PlannedMaintenance;
}

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private currentStatus$ = new BehaviorSubject<StatusSummary | undefined>(undefined);

  constructor(private http: HttpClient) {
    this.fetchStatus().subscribe();  // Optionally trigger status fetch on service creation
  }

  fetchStatus(): Observable<StatusSummary> {
    return this.http.get<StatusSummary>(STATUSPAGE).pipe(
      retry(3),  // Retry the request up to 3 times
      tap((status) => this.currentStatus$.next(status)),
      catchError((error) => {
        console.error('Error fetching status', error);
        this.currentStatus$.next(undefined);
        return throwError(error);
      })
    );
  }

  public getStatus(): Observable<StatusSummary | undefined> {
    console.log('getStatus');
    return this.currentStatus$.asObservable();
  }
}
